<template>
  <div class="order-container">
    <div class="queryItems mb10">
      <el-input v-model="querys.code" @clear="clearQuery" size="mini" class="mr15" clearable style="width: 50rem" placeholder="多个订单号用逗号或换行符分隔"></el-input>
      <el-input v-model="querys.rem" @clear="clearQuery" size="mini" class="mr15" clearable style="width: 20rem" placeholder="备注"></el-input>
      <el-button type="primary" size="mini" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
      <el-badge :hidden="!badgeNum" :value="badgeNum" class="item ml30 mr30">
        <el-button type="primary" size="mini" icon="el-icon-s-operation" @click="showFilter">筛选</el-button>
      </el-badge>
      <el-button type="primary" class="mr15" size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
      <download-excel
          class="export-excel-wrapper"
          :data="multipleSelection"
          :fields="fields"
          :name="timestampName"
          worksheet="sheet1"
      >
        <el-button type="primary" size="mini" icon="el-icon-folder-opened" @click="resetForm">导出</el-button>
      </download-excel>
    </div>
    <el-table
        :data="tableData"
        border
        ref="multipleTable"
        :height="tableHeight"
        :max-height="tableHeight"
        @selection-change="handleSelectionChange"
        style="width: 100%"
        default-expand-all
        v-loading="loading"
    >
      <el-table-column
          type="selection"
          width="55"></el-table-column>
      <el-table-column type="expand" class-name="expand-row">
        <template slot-scope="scope">
          <el-table
              :data="scope.row.OrderItemDetails"
              border
              row-class-name="success-row"
              header-cell-class-name="header-row"
              style="width: 100%"
          >
            <el-table-column label="商品标题" prop="ItemName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="品名/规格" prop="GoodsName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="照片" align="center" width="80px">
              <template slot-scope="slotScope">
                <el-image class="shopee-img" :preview-src-list="scope.row.OrderItemDetails.map(item => { return item.Img })" :src="slotScope.row.Img" v-if="slotScope.row.Img"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="SKU" prop="Sku" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="数量" prop="Amount" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="价格" prop="Price" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存扣除状态" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span>{{ slotScope.row.DeductionTime ? '已扣除' : '未扣除' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="库存状态" prop="StockStateTxt" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存数量/冻结数量" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span>{{ `${slotScope.row.StockQuantity}/${slotScope.row.FrozenQuantity}` }}</span>
              </template>
            </el-table-column>
            <el-table-column label="可出货数量" prop="ShippingQuantity" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="订单号" prop="MainBillCode" show-overflow-tooltip align="center" width="160">
      </el-table-column>
      <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column :label="$t('order.carrier')" width="135" show-overflow-tooltip prop="CarrierName" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip prop="CarrierBillCode" :label="$t('order.carrierbillcode')" align="center" width="130px">
      </el-table-column>
      <el-table-column :label="$t('order.timer')" show-overflow-tooltip prop="TimerName" align="center"></el-table-column>
      <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column label="物流状态" show-overflow-tooltip prop="TrackContent" align="center"></el-table-column>
      <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
      <el-table-column label="下单时间" width="160" prop="CreateTime	" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="140">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="outBoundDetail(scope.row.Id)">发货查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <shopee-drawer :initial-query="drawerQuery" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer"></shopee-drawer>
    <out-bound-detail @close="handleDetailClose" :order-id="currentStateId" :show="detailVisible" :list-data="outBoundData"></out-bound-detail>
  </div>
</template>

<script>
import {getOrderItemDetail, getOrderList} from "@/api/member";
import ShopeeDrawer from "@/views/member/shopee/components/shopeeDrawer.vue";
import { cancelOrderToDelivery } from "@/api/cod";
import outBoundDetail from "@/views/member/shopee/components/outBoundDetail.vue";
export default {
  components: {outBoundDetail, ShopeeDrawer },
  data() {
    return {
      queryCode: "",
      currentStateId: 0,
      PageIndex: 1,
      PageRows: 10,
      total: 0,
      loading: false,
      drawer: false,
      detailVisible: false,
      outBoundData: [],
      tableData: [],
      multipleSelection: [],
      querys: {},
      drawerQuery: {
        dateRange: []
      }
    }
  },
  created() {
    this.getData()
  },
  filters: {
    formatType(val) {
      if (val) {
        return "是"
      } else {
        return "否"
      }
    }
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 15rem - 100px)'
    },
    timestampName() {
      const timestamp = Date.now()
      return `COD订单-${timestamp}.xls`
    },
    fields() {
      const tableFields = {}
      tableFields['订单号'] = 'MainBillCode'
      tableFields['承运单号'] = 'CarrierBillCode'
      return tableFields
    },
    badgeNum() {
      let count = 0;
      for (let key in this.drawerQuery) {
        if (Array.isArray(this.drawerQuery[key])) {
          if (this.drawerQuery[key].length) {
            count++;
          }
        } else {
          if (this.drawerQuery[key] !== undefined && this.drawerQuery[key] !== '') {
            count++;
          }
        }
      }
      return count;
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDetailClose() {
      this.detailVisible = false
    },
    handleDrawerClose() {
      this.drawer = false
    },
    getClasses(state) {
      if (state === 0) {
        return "info-ball"
      } else if (state === 1) {
        return "success-ball"
      } else {
        return "warning-ball"
      }
    },
    outBoundDetail(OrderId) {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        OrderId: OrderId
      }
      getOrderItemDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.outBoundData = Data.map(item => {
            return {
              ...item,
              SkuDetail: {}
            }
          })
          this.currentStateId = OrderId
          this.detailVisible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    cancelToBill(row) {
      this.$confirm(`是否取消订单编号为（${row.MainBillCode}）的订单发货，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OrderId: row.Id
        }
        cancelOrderToDelivery(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("发货成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    showFilter() {
      this.drawer = true
    },
    handleConfirm(query) {
      this.drawerQuery = query
      this.handleDrawerClose()
      this.getData()
    },
    handleCommand(event, row) {
      if (event === 'unBind') {
        this.unBindSku(row)
      }
    },
    handleChange(val) {
      this.PageIndex = val
      this.getData()
    },
    clearQuery() {
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.querys = {
        dateRange: []
      }
      this.clearQuery()
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    getData() {
      let QueryCodes = this.querys.code ? this.querys.code.split(/[，,\s\r\n\t]+/) : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        Type: this.orderType,
        QueryCodeType: 3,
        QueryCodes: QueryCodes,
        CarrierId: this.drawerQuery.carrier || undefined,
        WareHouseIds: this.drawerQuery.warehouse ? [this.drawerQuery.warehouse] : undefined,
        DestinationId: this.drawerQuery.destination || undefined,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        QueryType: 1,
        OrderSource: 5,
        ElectronicShopId: this.drawerQuery.ElectronicShopId || undefined,
        Rem: this.querys.rem,
        IsCancel: this.drawerQuery.orderType || undefined,
        OrderStatu: 2
      }
      this.loading = true
      getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
          this.$nextTick(() => {
            this.$refs.multipleTable.bodyWrapper.scrollTop = 0
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.default-font {
  width: 60px;
}
.warning-ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ef0606;
}

.success-ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #08c421;
}

.info-ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #727070;
}
::v-deep .success-row {
  background: #f0f9eb;
}
::v-deep .header-row {
  background: oldlace !important;
}
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
.order-container {
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
  padding: 2rem 2.5rem;
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
  .arrived-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #19B14C;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .unarrive-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #ED4014;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.4rem;
  }
}
</style>
